export const NAME = 'EN APARTÉ'
export const TITLE = 'appartement coiffeur'
export const INTRO = [
  'Stéphanie a créé un appartement coiffeur intimiste et cosy.',
  'Pour une détente maximum, elle vous accueille sur rendez-vous uniquement et sublime vos cheveux dans le calme et la convivialité',
]

export const CONTENT = {
  PRESENTATION: {
    INTRO: {
      title: 'En aparté',
      content: ['Un style raffiné, un salon baigné de lumière, un cadre chaleureux et intimiste'],
    },
  },
  WHOAMI: {
    title: 'Qui suis-je ?',
    content: [
      'Après une reconversion professionnelle, cette passionnée de coiffure depuis toujours se consacre à la beauté de nos cheveux depuis 2007.',
      'D’abord employée chez un célèbre coiffeur messin, elle décide il y a cinq ans de se mettre à son compte et propose alors ses services de coiffure à domicile. Forte de son succès et de l’excellent relationnel qu’elle crée avec ses client(e)s, elle choisit quelques années plus tard de créer un concept qui lui tient à coeur depuis longtemps : un appartement-coiffure. Habitante d’un village voisin, elle choisit alors Le Ban-Saint-Martin, « pour sa qualité de vie et son attractivité ». Dès l’entrée, on se sent unique et presque comme chez soi. Seul(e), en couple ou en famille, les prestations sont les mêmes que dans un salon classique, le confort et le calme d’un salon privé en plus. « Je travaille uniquement sur rendez-vous, ce qui me permet d’offrir à mes client(e)s un cadre intimiste et untravail soigné. Je prends le temps d’écouter et de comprendre, afin de proposer une prestation unique, qui répondra parfaitement aux besoins de la personne. »',
      'Après plus de deux mois de confinement, les retrouvailles avec sa clientèle de longue date étaient très attendues. Même si l’activité a redémarré rapidement, le secteur de la coiffure prévoit une baisse de 17 % de l’activité pour le dernier trimestre 2020, par rapport à 2019. « Les habitudes des gens ont vraiment changé, on relativise beaucoup de choses après cette période compliquée et les visites s’espacent ». Pourtant, dans ce contexte particulier, le concept d’un appartement-coiffure privatisé pour chaque rendez-vous est devenu un luxe très apprécié et la fréquentation augmente.',
      'Si Stéphanie est disponible pour une clientèle plus nombreuse, elle apprécie d’entretenir une relation privilégiée avec celles et ceux qui lui font confiance depuis ses débuts en tant que coiffeuse indépendante ; Bien qu’elle concentre son activité dans son salon du Ban-Saint-Martin, il lui arrive encore de coiffer à domicile pour certaines occasions. Fidèle à son nom, En Aparté propose une expérience dans l’air du temps. On y prend plaisir à se détendre et à se faire chouchouter par les mains expertes de Stéphanie. Le temps d’une pause pour soi, on y reçoit des conseils et des soins personnalisés, en toute convivialité.',
    ],
  },
  MARKER: {
    title: 'En Aparté',
    content: ['2 Rue Saint-Sigisbert, 57050 Le Ban-Saint-Martin', 'Sur rendez-vous'],
  },
  PRESTA: ['Coiffure Femme', 'Coiffure Homme', 'Coupe/Brushing', 'Balayage', 'Coloration', 'Ombré Hair'],
  ADVICE: ['Visagiste', 'Conseils', 'Soins capillaires', 'Contrat Schwartzkopf', 'Contrat Davines'],
  MEETING: {
    GENERAL: {
      title: 'Déroulement d’une séance ',
      content: [
        '<strong>La première séance est essentielle</strong> car elle me permet de cerner votre demande en première intention. De votre côté, elle vous permet de saisir si l’espace thérapeutique que je vous propose vous correspond.',
        'Lors de cet entretien, nous évoquons les motifs qui vous ont menés à cette démarche de consultation.',
        'Nos échanges m’amèneront à établir si vos attentes relèvent bien de mon domaine de compétences ou s’il m’apparait plus opportun pour vous que je vous réoriente vers un confrère.',
        'En fonction de la singularité de chaque situation, plusieurs séances (rarement plus de 2 ou 3) pourront être nécessaires pour qu’ensemble nous parvenions à cibler la voie qui sera la plus pertinente pour vous.',
        'Cette première rencontre a également pour vocation de constituer un espace pour répondre à vos questions, craintes éventuelles etc…',
        'Nous aborderons  le déroulement des séances à venir, leur rythme,  toujours dans la perspective d’une construction partagée et personnalisée de l’accompagnement envisagé ( entretien de soutien, accompagnement centré sur la personne, prise en charge psychothérapique spécifique).',
        'La séance est réfléchie sur une heure. La durée de notre entretien s’établit sur 45/50 min et un temps de reprise pour moi post séance (notes) favorise une continuité pour une prise en charge ajustée entres les rendez-vous.',
      ],
    },
    CHILDREN: {
      title: 'En ce qui concerne l’accompagnement psychologique des enfants :',
      content: [
        'Un premier entretien en présence des parents est souhaitable, afin de comprendre l’origine supposée, partagée ou non avec l’autre parent, avec l’enfant de la difficulté constatée.',
        'La configuration des séances alors établie pourra être variable alternant des temps d’échanges auprès de chacun des parents pour recueillir leurs points de vue et observations puis des temps de prises en charge auprès de l’enfant seul ou accompagné de son parent.',
        'En fonction de l’âge de l’enfant la durée de la séance  auprès de lui pourra être réduite au profit de ces moments d’accompagnement et de guidance parentale.',
        'La psychothérapie d’enfants prend une forme spécifique qui s’adapte continuellement en fonction de leurs capacités d’attention et d’élaboration verbale.',
        'Aussi la psychothérapie de l’enfant réinterroge continuellement la créativité du psychologue qui doit ajuster son accompagnement par l’utilisation de supports facilitants comme le jeu ou le dessin, l’arbre de vie etc…',
        'Le thérapeute vient à la rencontre de son petit patient là où il est et là où il en est…',
        'En ce qui concerne la prise en charge psychologique des adolescents et en fonction des besoins, le suivi psychothérapique sera davantage dédié à leur rencontre spécifique.',
        'Il s’agit d’offrir un espace de parole neutre qui leur est consacré (ce qui est particulièrement important à cette étape fondamentale de la construction identitaire) et qui favorisera l’élaboration d’une alliance thérapeutique de qualité.',
      ],
    },
  },
  FEES: {
    title: 'Honoraires',
    meta: {
      title: 'Honoraires',
      description: 'Honoraires des séances pour mes patients enfant, adolescents, adultes et professionnels',
    },
    content: [
      'Accompagnement individuel (45 à 50 min) : 60 euros (adulte, adolescent, enfant)',
      'Accompagnement entretien de couple, guidance parentale conjointe (1 heure) : 80 euros',
      'Accompagnement entretien motivationnel professionnel : 80 euros',
      'Supervision individuelle : 60 euros',
      'Analyse des pratiques professionnelles en institution : sur devis',
    ],
    disclaimer: [
      'Les consultations devront être réglées à chaque fin de séance. Le règlement pourra se faire par chèque ou en espèces.',
      'Les consultations ne sont pas remboursées par la sécurité sociale. En revanche, certaines mutuelles prennent en charge ( partiellement ou totalement, selon les modalités de votre contrat) les séances chez un psychologue clinicien psychothérapeute (certifié <abbr title="Agence Régionale de Santé">ARS</abbr>) ce qui est mon cas.',
      'Une note d’honoraire vous sera fournie à votre demande à l’issue de chacune des séances, afin que vous puissiez bénéficier des remboursements prévus dans votre contrat le cas échéant.',
    ],
  },
  CONTACT: {
    meta: {
      title: 'Contact',
      description:
        "Voici les informations permettant de ma contacter et de me joindre ainsi qu'une carte pour situer mon cabinet",
    },
    GENERAL: {
      title: 'Me contacter',
      content: [
        'Les consultations se tiennent sur rendez-vous UNIQUEMENT.',
        'Le cabinet se situe sur la commune de Dieulouard (54380) au 13 rue du stade.',
        'Pour toute information complémentaire, vous pouvez me contacter par mail : <a href="mailto:emiliepfister016@gmail.com">emiliepfister016@gmail.com</a>',
      ],
    },
    APPOINTMENTS: {
      title: 'Pour prendre rendez-vous je suis joignable :',
      content: [
        'Par téléphone de 9h à 17h30 du lundi au vendredi au <a href="tel:07 80 97 92 98">07 80 97 92 98</a>',
        'Pendant les temps de consultations, n’hésitez pas à laisser votre message sur le répondeur téléphonique, je vous recontacterai dès que possible.',
        'Vous pouvez également prendre rendez-vous sur <a href="https://doctolib.fr/psychologue/dieulouard/emilie-pfister" rel="noopener noreferrer external me" target="_blank">Doctolib.fr</a>, des plages horaires en ligne y sont dédiées.',
      ],
    },
  },
  PAGE_NAV: {
    title: 'Un accompagnement et une approche personnalisés',
    subtitle:
      "Enfants, adolescents, adultes, professionnels, je vous apporterai un soutien lors d'étapes difficiles de la vie engendrant une souffrance et une perte d'estime de soi et vous aiderai à comprendre vos difficultés, à les dénouer et à trouver des solutions adaptées.",
    items: [
      {
        title: 'Qui suis-je',
        content: [
          {
            title: 'Psychologue clinicien - Psychothérapeute',
            to: '/psychologue-clinicien-psychotherapeute',
          },
          { title: 'Le cabinet', to: '/le-cabinet' },
        ],
        icon: 'LightBulb',
        to: '/presentation',
      },
      {
        title: 'Consultations',
        content: [
          {
            title: 'Pourquoi consulter ?',
            to: '/consultation',
          },
          {
            title: 'Public accueilli',
            to: '/consultation/public-accueilli',
          },
          {
            title: `Déroulement d'une séance`,
            to: 'consultation/deroulement-seance',
          },
        ],
        icon: 'Psychology',
        to: '/consultation',
      },
      {
        title: 'Honoraires',
        content: [{ title: 'Mes différentes prestations', to: '/honoraires' }],
        icon: 'Payments',
        to: '/honoraires',
      },
      {
        title: 'Contact',
        content: [{ title: 'Me contacter', to: '/contact' }],
        icon: 'Location',
        to: '/contact',
      },
    ],
  },
  OFFICE: {
    title: 'Le cabinet',
    content: [
      'Le cabinet où je pratique est situé sur la commune de Dieulouard (54380).',
      '<a class="link" href="https://www.google.com/maps/place/13+Rue+du+Stade,+54380+Dieulouard/@48.8399136,6.0638741,17z/data=!3m1!4b1!4m5!3m4!1s0x4794b902db501775:0xcac710dff334770!8m2!3d48.8399136!4d6.0660628" rel="noopener noreferrer external" target="_blank">Vous pouvez vous y rendre facilement en voiture</a> ou par le train depuis Nancy (54000) situé à 25 kms environ.',
      `Des photos sont à venir, vous trouverez d'autres renseignements sur la page <span class="italic">contact</span>`,
    ],
  },
  MARKER: {
    title: 'EN APARTÉ',
    content: [
      '2 rue Saint Sigisbert, 57050 LE BAN ST MARTIN',
      'Sur rendez-vous',
      '<a href="tel:0625822902">06 25 82 29 02</a>',
    ],
  },
}

export const APPOINTMENT_BOOK = 'Prendre rendez-vous'
export const CONTACT_INFOS = {
  PHONE: '06 25 82 29 02',
  EMAIL: 'null@gmail.com',
  ADDRESS: '2 rue Saint Sigisbert, 57050 LE BAN ST MARTIN',
}
