import React from 'react'
import { CONTENT } from '../../content'
import * as styles from './Marker.module.scss'

const Marker = () => (
  <div className={styles.Marker}>
    <header className={styles.header}>
      <h6>{CONTENT.MARKER.title}</h6>
    </header>

    <div>
      {CONTENT.MARKER.content.map((content, index) => (
        <p key={index} dangerouslySetInnerHTML={{ __html: content }} className={styles.p} />
      ))}

      <div className={styles.arrow_up} />
    </div>
  </div>
)
//  : (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 24 24"
//     style={{ height: 24, width: 24 }}
//   >
//     <path d="M0 0h24v24H0z" fill="none" />
//     <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z" />
//   </svg>
// )

export default Marker
