import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import ImageBackground from 'gatsby-background-image'
import * as styles from './Hero.module.scss'
import * as linkStyles from '../../UI/Link/Link.module.scss'
import FlowerVector from '../../illustrations/flowers'
import { INTRO } from '../../../content'
import SiteTitle from '../../Blocks/SiteTitle/SiteTitle'
import { graphql, useStaticQuery } from 'gatsby'

const Hero = ({ isMobile, children }) => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "DSC_4988.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile: file(relativePath: { eq: "DSC_4865_1_blur.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const desktopImage = data.desktop?.sharp.fluid
  const mobileImage = data.mobile?.sharp.fluid
  return (
    // <section className={styles.hero} id="presentation">
    //   <div className={`wrapper ${styles.hero_wrapper}`}>
    //     {/* <FlowerVector className={styles.flowers} /> */}

    //     <div className={styles.block}>
    //       <section className={styles.content}>
    //         {children || INTRO.map((content, index) => <p key={index} dangerouslySetInnerHTML={{ __html: content }} />)}
    //       </section>
    //     </div>
    //   </div>
    // </section>
    // <section style={{ display: 'grid' }} id="presentation">
    //   <StaticImage
    //     src="../images/salon.jpg"
    //     layout="fullWidth"
    //     style={{
    //       gridArea: '1/1',
    //       // You can set a maximum height for the image, if you wish.
    //       // maxHeight: 600,
    //     }}
    //     // You can optionally force an aspect ratio for the generated image
    //     // aspectRatio={3 / 1}
    //     quality={95}
    //     formats={['AUTO', 'WEBP', 'AVIF']}
    //     alt="A Gatsby astronaut"
    //     style={{ marginBottom: `1.45rem`, zIndex: 0 }}
    //   />
    //   <div
    //     style={{
    //       // By using the same grid area for both, they are stacked on top of each other
    //       gridArea: '1/1',
    //       position: 'relative',
    //       // This centers the other elements inside the hero component
    //       placeItems: 'center',
    //       display: 'grid',
    //     }}
    //   >
    //     {/* Any content here will be centered in the component */}
    //     <SiteTitle />
    //   </div>
    // </section>
    <ImageBackground Tag="section" fluid={isMobile ? mobileImage : desktopImage} className={styles.section}>
      <SiteTitle />
    </ImageBackground>
  )
}

export default Hero
