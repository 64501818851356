import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import Img from 'gatsby-image'
import * as styles from './Gallery.module.scss'

const Gallery = () => {
  const [state, setState] = useState({ photoIndex: 0, isOpen: false })
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { regex: "/(jpg)/" }, relativeDirectory: { eq: "gallery" } }) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const images = data?.allFile?.edges
  const lightBoxImages = images?.map(image => image?.node?.childImageSharp.fluid.srcWebp)
  const alts = [
    'Le bac à shampoing',
    'Fauteuil dans lequel vous serez installé lors de la coupe',
    'les différents soins capillaires proposés',
    'Espace coupe avec sa lampe et son chargeur de téléphone',
    "Canapé de l'espace détente ainsi que le présentoir des produits de soins à vendre au salon",
    "Stéphanie en pleine coupe d'une cliente",
    "La coiffeuse coupant les cheveux d'une cliente",
    "Vue de l'espace détente avec son cactus face au balcon",
    'Stéphanie de dos, réalisant une coupe femme',
    'Stéphanie de trois quart, réalisant une coupe à une cliente du salon de coiffure',
    "L'espace détente du salon avec, au premier plan, le bac à shampoing",
    "Un photo des deux espaces coiffure prise de l'espace détente",
  ]

  return !images ? null : (
    <div className={styles.gallery__grid} role="list">
      {images.map((image, index) => (
        <button
          key={index}
          role="listitem"
          className={styles.gallery__button}
          onClick={() =>
            setState(prevState => ({
              photoIndex: index,
              isOpen: true,
            }))
          }
        >
          <Img alt={alts[index]} className={styles.gallery__thumbnail} fluid={image.node.childImageSharp?.fluid} />
        </button>
      ))}
      {state.isOpen && images ? (
        <Lightbox
          mainSrc={lightBoxImages[state.photoIndex]}
          nextSrc={lightBoxImages[4]}
          prevSrc={lightBoxImages[(state.photoIndex + lightBoxImages.length - 1) % lightBoxImages.length]}
          onCloseRequest={() => setState({ isOpen: false })}
          enableZoom={false}
          onMovePrevRequest={() =>
            setState(prevState => ({
              ...prevState,
              photoIndex: (prevState.photoIndex + lightBoxImages.length - 1) % lightBoxImages.length,
            }))
          }
          onMoveNextRequest={() =>
            setState(prevState => ({
              ...prevState,
              photoIndex: (prevState.photoIndex + 1) % lightBoxImages.length,
            }))
          }
        />
      ) : null}
    </div>
  )
}
export default Gallery
