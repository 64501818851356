import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { StaticImage } from 'gatsby-plugin-image'
import Hero from '../components/Blocks/Hero/Hero'
import Map from '../components/Map'
import Stephanie from '../components/Blocks/Stephanie/'
import { Script } from 'gatsby'

import { Layout } from '../layouts'
import Seo from '../components/seo'
import { CONTENT, INTRO } from '../content'
import SiteTitle from '../components/Blocks/SiteTitle/SiteTitle'
import Gallery from '../components/Gallery/Gallery'
import Contact from '../components/Blocks/Contact/Contact'

const PLANITY_SCRIPTS = ['https://d2skjte8udjqxw.cloudfront.net/widget/production/polyfills.latest.js', 'https://d2skjte8udjqxw.cloudfront.net/widget/production/app.latest.js']
// const PLANITY_SCRIPTS = [<script src="" />, <script key={1} src="https://d2skjte8udjqxw.cloudfront.net/widget/production/app.latest.js" />]

// const Script = () => PLANITY_SCRIPTS

const IndexPage = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const container = document.getElementById('reservation')

    if (container) {
      window.planity = {
        key: '-MnUxIr-zIRiK3oNPmMm',
        primaryColor: 'black',
        // choisissez une des options ci-dessous
        container,
        // accountContainer: container,
        // appointmentContainer: document.getElementById('book'),
        // giftVoucherContainer: container,
        // onlineShopContainer: container
      }
      // const scriptTags = PLANITY_SCRIPTS.map(src => {
      //   const scriptTag = document.createElement('script')
      //   scriptTag.setAttribute('src', src)
      //   return scriptTag
      // })
      // document.body.append(...scriptTags)
    }
    
  }, [])

  // useEffect(() => {
  //   const container = document.getElementById('book')

  //   if (container) {
  //     window.planity = {
  //       key: '-MnUxIr-zIRiK3oNPmMm',
  //       primaryColor: 'black',
  //       // choisissez une des options ci-dessous
  //       container,
  //       // accountContainer: container,
  //       // appointmentContainer: document.getElementById('book'),
  //       // giftVoucherContainer: container,
  //       // onlineShopContainer: container
  //     }
  //   }
  // }, [])

  useEffect(() => {
    setIsMobile(/mobi/i.test(navigator.userAgent))
  }, [])
  return (
    <Layout>
      <Seo title="EN APARTÉ - appartement coiffeur" description={INTRO[0]} />
      <Hero isMobile={isMobile} />

      <Helmet>
        {PLANITY_SCRIPTS.map(src => <script src={src} key={src} />)}
      </Helmet>

      {/* <PageNav /> */}
      <section id="presta" className="presta section">
        <div className="wrapper">
          <header>
            <h2>Prestations</h2>
          </header>

          <div className="presta-content__wrapper">
            <ul className="presta-list">
              {CONTENT.PRESTA.map((presta, i) => (
                <li key={i} className="presta-list__item">
                  {presta}
                </li>
              ))}
            </ul>
            <div>
              <p>Mais aussi :</p>
              <ul className="presta-list">
                {CONTENT.ADVICE.map((advice, i) => (
                  <li key={i} className="presta-list__item">
                    {advice}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="presentation" className="presentation section">
        <div className="wrapper">
          <header>
            <h2>Le salon</h2>
          </header>

          <div>
            {INTRO.map((p, index) => (
              <p key={index}>{p}</p>
            ))}
          </div>

          <Gallery />
        </div>
      </section>

      <Stephanie />

      <section id="reservation" style={{ minHeight: 500 }} />
      {/* <Script /> */}
      <Contact />
    </Layout>
  )
}

export default IndexPage
