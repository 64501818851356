import React from 'react'
import { TITLE, NAME, CONTENT } from '../../../content'
import * as styles from './SiteTitle.module.scss'
import Scissors from '../../Scissors/Scissors'

const SiteTitle = ({ tagLevel = 1, tag, className }) => {
  const Heading = !tag ? `h${tagLevel}` : tag
  const Secondary = !tag ? `h${tagLevel + 1}` : tag

  return (
    <div className={`${styles.section} section ${className ? className : ''}`}>
      <div className={`wrapper ${styles.wrapper}`}>
        <hgroup className={styles.hgroup}>
          <div className={styles.heading__wrapper}>
            <Heading className={`${styles.h1} white`}>{NAME}</Heading>
          </div>
          <Secondary className={styles.h2}>{TITLE}</Secondary>
        </hgroup>
        {/* <p style={{ color: 'white' }}>{CONTENT.PRESENTATION.INTRO.content[0]}</p> */}
      </div>
    </div>
  )
}

export default SiteTitle
