import React from 'react'
import GoogleMapReact from 'google-map-react'
import Marker from './Marker'

export default function SimpleMap() {
  const defaultProps = {
    center: {
      lat: 49.12157375310735,
      lng: 6.149490022123178,
    },
    zoom: 17,
  }

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '60vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyBqio5EdM8Dq-p1DKlKxaHkSGH7-WyqKls',
          v: '3.32',
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        draggable={false}
      >
        <Marker />
      </GoogleMapReact>
    </div>
  )
}
