import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import { CONTENT, INTRO } from '../../../content'
import SiteTitle from '../../../components/Blocks/SiteTitle/SiteTitle'
import * as styles from './Stephanie.module.scss'

const Stephanie = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "DSC_5065.JPG" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section id="qui-suis-je" className="presentation section presentation--stephanie">
      <div className="wrapper">
        <header>
          <h2>{CONTENT.WHOAMI.title}</h2>
        </header>
        <div className={styles.flexWrapper}>
          <Img className={styles.image} fluid={data?.file?.sharp?.fluid} />

          <article className={styles.content}>
            <blockquote
              className={styles.blockquote}
              cite="https://www.ban-saint-martin.fr/fileadmin/user_upload/ban-st-martin/pdf_dossiers/2020-BIM26-HD.pdf"
            >
              {CONTENT.WHOAMI.content.map((c, i) => (
                <p key={i}>{c}</p>
              ))}
            </blockquote>
            <p>
              <a
                href="https://www.ban-saint-martin.fr/fileadmin/user_upload/ban-st-martin/pdf_dossiers/2020-BIM26-HD.pdf"
                target="_blank"
                rel="external noopener noreferrer"
              >
                <small>Extrait du magazine BIM d'octobre 2020</small>
              </a>
            </p>
          </article>
        </div>
      </div>
    </section>
    // <Layout>
    //   <Seo title="Emilie Pfister - Présentation" description={INTRO[1]} />
    //   <SiteTitle tag="span" />

    //   <div className="wrapper">
    //     <BackgroundImage
    //       className={styles.Presentation}
    //       Tag="section"
    //       fluid={image?.sharp?.fluid}
    //       style={{ backgroundSize: 'contain' }}
    //       id="presentation"
    //     />
    //     <h1>{CONTENT .WHOAMI.title}</h1>

    //     {CONTENT.WHOAMI.content.map((content, index) => (
    //       <p key={index} dangerouslySetInnerHTML={{ __html: content }} />
    //     ))}
    //   </div>
    // </Layout>
  )
}

export default Stephanie
