import React, { useState, useEffect, useRef } from 'react'
import Map from '../../Map'
import * as styles from './Contact.module.scss'

const Contact = () => {
  const scrollRef = useRef(null)
  const [intersecting, setIntersecting] = useState(false)

  useEffect(() => {
    if (scrollRef.current) {
      const options = {
        rootMargin: '0px',
      }

      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIntersecting(true)
          }
        })
      }, options)

      observer.observe(scrollRef.current)
    }
  }, [])
  return (
    <section id="location" className={`presentation section ${styles.contact__section}`}>
      <div className="wrapper">
        <header>
          <h2>Nous trouver</h2>
        </header>

        <p itemScope itemType="https://schema.org/PostalAddress">
          Le salon est situé au <span itemProp="streetAddress">2 Rue Saint-Sigisbert</span>
          <span itemProp="postalCode">&nbsp;57050</span>
          <span itemProp="addressLocality">&nbsp;Le Ban-Saint-Martin</span>
          <meta itemProp="addressCountry" content="France" />
        </p>

        <div style={{ margin: '2rem 0 1rem 0' }}>
          <a
            target="_blank"
            rel="external noopener noreferrer nofollow"
            href="https://www.google.com/maps/dir//En+apart%C3%A9,+2+Rue+Saint-Sigisbert,+57050+Le+Ban-Saint-Martin/@49.1262961,6.1396902,16z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4794d99cfeba19bf:0xd82536b0a90c57ec!2m2!1d6.1494859!2d49.1215707!3e0?hl=fr"
          >
            Accéder à l'itinéraire via Google Maps
          </a>
        </div>

        <div ref={scrollRef}>{intersecting ? <Map /> : null}</div>
      </div>
    </section>
  )
}

export default Contact
