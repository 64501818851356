import React, { useState, useEffect, useRef } from 'react'
import * as styles from './BookCTA.module.scss'
import * as buttonStyles from '../UI/Button/Button.module.scss'
import * as linkStyles from '../UI/Link/Link.module.scss'
import Link from '../UI/Link'
import EmailIcon from '../Icons/Email'
import PhoneIcon from '../Icons/PhoneIcon'
import CalendarIcon from '../Icons/Calendar'
import { APPOINTMENT_BOOK, CONTENT } from '../../content'
import { CONTACT_INFOS } from '../../content'

const BookCTA = () => {
  const [open, setOpen] = useState(false)
  const menuRef = useRef(null)

  function handleClickOutside(event) {
    if (menuRef.current) {
      if (!menuRef.current.contains(event.target) || event.key === 'Escape') {
        setOpen(false)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => document.removeEventListener('click', handleClickOutside)
  }, [])

  return (
    <div className={styles.book_cta__wrapper} ref={menuRef}>
      <div className={`${styles.book_menu} ${open ? styles.open : ''}`}>
        <ul>
          <li>
            <Link className={linkStyles.blue} href={`tel:${CONTACT_INFOS.PHONE}`} title="Contactez moi par téléphone">
              <PhoneIcon />
              <span>Par téléphone</span>
            </Link>
          </li>
          <li>
            <Link
              className={linkStyles.blue}
              href={`sms://${CONTACT_INFOS.PHONE}&body=${encodeURI(
                "Bonjour je souhaiterais prendre RDV s'il vous plait",
              )}`}
              title="Contactez moi par mail en attendant que le site soit terminé"
            >
              <EmailIcon />
              <span>Par SMS</span>
            </Link>
          </li>
        </ul>
      </div>
      <button
        className={`${styles.book_cta} ${buttonStyles.Button} ${buttonStyles.white}`}
        onClick={() => setOpen(!open)}
        onKeyDown={({ key }) => (key === 'Escape' ? setOpen(false) : null)}
      >
        <CalendarIcon />
        {APPOINTMENT_BOOK}
      </button>
    </div>
  )
}

export default BookCTA
