import React from 'react'
import { Link } from 'gatsby'
import { CONTENT } from '../../../content'
import SiteTitle from '../SiteTitle/SiteTitle'
import * as styles from './Footer.module.scss'

const Footer = () => (
  <footer className={`wrapper ${styles.footer}`}>
    <p style={{ color: '#fff' }} lang="en">
      <small>
        Photographs, design and code by&nbsp;
        <a target="_blank" href="https://www.linkedin.com/in/terrydiluzio/" rel="external noopener noreferrer">
          t3_rrY
        </a>
      </small>
    </p>
  </footer>
)

export default Footer
